import Typography from 'components/shared/generic/typography/presentational/Typography';
import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import useTranslate from 'lib/languages/useTranslate';

interface IssueCardModalProps {
    handleSubmit: () => void;
    closeModal: () => void;
}

const IssueCardModal = ({ closeModal, handleSubmit }: IssueCardModalProps) => {
    const translate = useTranslate('cards.issueCardModal');

    const handleIssueCard = () => {
        closeModal();
        handleSubmit();
    };

    return (
        <ModalOuterContainer
            isPosting={false}
            handleSubmit={handleIssueCard}
            className="add-cardholder-fee-modal"
            hideModal={closeModal}
        >
            <Typography tag="h1">{translate('titleText')}</Typography>
            <Typography className="description">
                {translate('descriptionText')}
            </Typography>
        </ModalOuterContainer>
    );
};

export default IssueCardModal;
